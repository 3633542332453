import moment from "moment";
import { getEnvironment } from "contexts/displayEnvironment";

import { JobTypes } from "./constants";

// buildurl is used by the fetchResources function to create the queryString
// that will determine what resources are fetched from the back end

export const buildUrl = (table, sortObject) => {
  let url = `/${table}?`;
  if (!sortObject) {
    return url;
  }
  const sortParams = Object.keys(sortObject);
  sortParams.forEach((key) => {
    if (sortObject[key] == null) {
      // catch bad data
      url = `${url + key}=`;
      url += "&";
    } else {
      if (typeof sortObject[key] === "object")
        url = `${url + key}=${JSON.stringify(sortObject[key])}`;
      else url = `${url + key}=${sortObject[key]}`;
      url += "&";
    }
  });
  return url;
};

export const amendUrl = (baseUrl, request) => {
  if (request.partner) {
    request.partner = request.partner.value;
  }
  if (request.job_type) {
    request.job_type = request.job_type.value;
  }
  return buildUrl(baseUrl, request);
};

export const buildCleanUrl = (path, json) => {
  let url = `/${path}?`;
  Object.keys(json).forEach((key) => {
    if (json[key] != null) {
      url = `${url + key}=${json[key]}`;
      url += "&";
    }
  });
  return url;
};

export const formatDateParamsToUTC = (sortObject) => {
  const startDate = sortObject.start_date;
  const endDate = sortObject.end_date;

  if (startDate || endDate) {
    sortObject.start_date = moment(startDate, "DD/MM/YYYY")
      .startOf("day")
      .utc()
      .format();
    sortObject.end_date = moment(endDate, "DD/MM/YYYY")
      .endOf("day")
      .utc()
      .format();
  }
  return sortObject;
};

// build state breaks down a url and creates a sortObject that can be fed
// into the fetchResources call. This is primarily used on mount of a Component
// or if a user tries to refresh the page.
export const buildState = (url, callback) => {
  const queryParams = {};
  if (url.length === 0) {
    return queryParams;
  }
  url
    .replace(/^\?/, "")
    .replace(/&$/, "")
    .split("&")
    .forEach((el) => {
      const keyValuePair = el.split("=");
      queryParams[keyValuePair[0]] = keyValuePair[1];
    });
  if (callback) {
    callback(queryParams);
  }
  return queryParams;
};

// helper method to save refresh state
const saveURL = (url) => {
  let save = "";
  if (url.match("localhost") === null) {
    save = url.split(".com")[1];
  } else {
    save = url.split("http://localhost:3001")[1];
  }
  return save || "";
};

/**
 * Wrapper around our API responses to redirect to the login page when a token
 * has expired
 * @param {object} response - The response object returned from the server
 * @returns {object | undefined} json - The decoded json body of the response
 */
const handleUnauthorized = (response) => {
  if (response.status === 401) {
    localStorage.removeItem("token");
    window.location = "/login";
    return;
  }
  return response.json();
};

/**
 * Builds webapp url based on the job type.
 * @param {number} jobType The type of verification job to be performed
 * must be an integer
 * @returns {string} A new url based on the job type
 */
const buildWebAppUrl = (jobType) => {
  const jobIdUrls = {
    [JobTypes.AML]: "aml_check",
  };
  return `/web_app/${jobIdUrls[jobType] || ""}`;
};

// Send the display env with every request, even if we don't intend to use it.
// Display env is stored as a boolean so we are able to treat it as a binary.
export const buildRequestHeaders = () => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${localStorage.token}`);
  headers.append("X-Smile-Env", getEnvironment());
  return headers;
};

export const login = (user) => {
  const headers = buildRequestHeaders();
  return fetch("/auth", {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify(user),
  })
    .then((response) => response.json())
    .then((json) => {
      if (json.auth_token) {
        localStorage.setItem("token", json.auth_token);
      } else if (json.pre_otp_user) {
        localStorage.setItem("preOTPUser", JSON.stringify(json.pre_otp_user));
      }
      return json;
    });
};

export const disable2FA = () => {
  const headers = buildRequestHeaders();
  return fetch(`/two_factor_authentication`, {
    method: "DELETE",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const signUp = (user) => {
  const headers = buildRequestHeaders();
  return fetch("/signup", {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify(user),
  })
    .then((response) => handleUnauthorized(response))
    .then((json) => {
      if (json.auth_token) {
        localStorage.setItem("token", json.auth_token);
      } else if (json.pre_otp_user) {
        localStorage.setItem("preOTPUser", JSON.stringify(json.pre_otp_user));
      }
      return json;
    });
};

export const getInvitation = (id) => {
  const headers = buildRequestHeaders();
  return fetch(`/invitation?invitation_id=${id}`, {
    method: "get",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const logout = () => {
  const headers = buildRequestHeaders();
  return fetch("/auth", {
    method: "DELETE",
    credentials: "include",
    headers,
  }).then((response) => {
    localStorage.removeItem("token");
    localStorage.removeItem("adminPartnerId");
    sessionStorage.removeItem("url");
    window.location = "/";
    return response.json();
  });
};

export const fetchJob = (jobId) => {
  const headers = buildRequestHeaders();
  return fetch(`/jobs/${jobId}`, {
    credentials: "include",
    headers,
  }).then((response) => {
    sessionStorage.setItem("url", `/job/${jobId}`);
    return handleUnauthorized(response);
  });
};

export const putRerunJob = (jobId, forceCharge) => {
  const headers = buildRequestHeaders();
  return fetch(`/jobs/${jobId}`, {
    credentials: "include",
    method: "PUT",
    headers,
    body: JSON.stringify({ force_charge: forceCharge }),
  }).then((response) => handleUnauthorized(response));
};

export const fetchJobFileLinks = (jobId) => {
  const headers = buildRequestHeaders();
  return fetch(`/jobs/files/${jobId}`, {
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const fetchSmileResults = (table, sortObject) => {
  const headers = buildRequestHeaders();
  const url = buildUrl(table, sortObject);

  return fetch(`${url}`, {
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const fetchResources = (table, sortObject) => {
  const headers = buildRequestHeaders();
  const url = buildUrl(table, sortObject);
  return fetch(`${url}`, {
    credentials: "include",
    headers,
  }).then((response) => {
    if (!/smile_results/g.exec(table)) {
      sessionStorage.setItem("url", saveURL(response.url));
    }
    return handleUnauthorized(response);
  });
};

export const submitReview = (reviewInfo) => {
  const headers = buildRequestHeaders();
  return fetch(`/reviews/${reviewInfo.id}`, {
    method: "PATCH",
    credentials: "include",
    headers,
    body: JSON.stringify(reviewInfo),
  }).then((res) => res.json());
};

export const fetchPendingReviewCount = () => {
  const headers = buildRequestHeaders();
  return fetch("/reviews/pending", {
    method: "GET",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const fetchPhoneVerifications = (jobId) => {
  const headers = buildRequestHeaders();
  return fetch(`/smile_results/phone_verifications/${jobId}`, {
    method: "GET",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const fetchPendingKYCReviewCount = () => {
  const headers = buildRequestHeaders();
  return fetch("/company_kyc_details/pending", {
    method: "GET",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const fetchCompanies = () => {
  const headers = buildRequestHeaders();
  return fetch("/companies", {
    method: "GET",
    credentials: "include",
    headers,
  }).then((response) => {
    sessionStorage.setItem("url", "/companies");
    return handleUnauthorized(response);
  });
};

export const fetchSelectedCompany = (id) => {
  const headers = buildRequestHeaders();
  return fetch(`/companies/${id}/edit`, {
    method: "GET",
    credentials: "include",
    headers,
  }).then((response) => {
    sessionStorage.setItem("url", `/companies/${id}/edit`);
    return handleUnauthorized(response);
  });
};

export const createOrUpdateCompany = (companyDetails) => {
  const headers = buildRequestHeaders();
  return fetch("/companies", {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify(companyDetails),
  }).then((response) => {
    if (response.status === 200) {
      return fetchCompanies().then((json) => json);
    }
    return handleUnauthorized(response);
  });
};

export const adjustWalletBalance = (data) => {
  const headers = buildRequestHeaders();
  return fetch(`/companies/wallet_balance`, {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify(data),
  }).then((response) => handleUnauthorized(response));
};

export const fetchPartnerSupportedCountries = (partnerId, signal) => {
  const url = partnerId
    ? `/enterprise/supported-countries?partner_id=${partnerId}`
    : "/enterprise/supported-countries";
  const headers = buildRequestHeaders();
  return fetch(url, {
    method: "GET",
    credentials: "include",
    headers,
    signal,
  }).then((response) => handleUnauthorized(response));
};

export const updateCompanyOverdraftAllowance = (data) => {
  const headers = buildRequestHeaders();
  return fetch(`/companies/overdraft_allowance`, {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify(data),
  });
};

export const getEditReview = (id) => {
  const headers = buildRequestHeaders();
  return fetch(`/reviews/${id}/edit`, {
    method: "GET",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const getReview = (id) => {
  const headers = buildRequestHeaders();
  return fetch(`/reviews/${id}`, {
    method: "GET",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const adjustReview = (json) => {
  const headers = buildRequestHeaders();
  return fetch(`/reviews/${json.id}/adjust`, {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify(json),
  }).then((response) => handleUnauthorized(response));
};

export const fetchDashboardMetrics = (options) => {
  const headers = buildRequestHeaders();
  return fetch(`/metrics`, {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify(options),
  }).then((response) => {
    sessionStorage.setItem("url", `/analytics`);
    return handleUnauthorized(response);
  });
};

export const fetchApprovedMetrics = (options) => {
  const headers = buildRequestHeaders();
  return fetch(`/metrics/approved`, {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify(options),
  }).then((response) => {
    sessionStorage.setItem("url", `/metrics`);
    return handleUnauthorized(response);
  });
};

export const fetchOutcomeMetrics = (options) => {
  const headers = buildRequestHeaders();
  return fetch(`/metrics/outcomes`, {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify(options),
  }).then((response) => {
    sessionStorage.setItem("url", `/analytics`);
    return handleUnauthorized(response);
  });
};

export const fetchIDStatusMetrics = (options) => {
  const headers = buildRequestHeaders();
  return fetch(`/metrics/id_uptimes`, {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify(options),
  }).then((response) => {
    sessionStorage.setItem("url", `/id_status`);
    return handleUnauthorized(response);
  });
};

export const fetchIDStatusMetricsByPartner = (options) => {
  const headers = buildRequestHeaders();
  return fetch(`/metrics/id_uptimes_by_partner`, {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify(options),
  }).then((response) => {
    sessionStorage.setItem("url", `/id_status`);
    return handleUnauthorized(response);
  });
};

export const fetchIDTypeMetrics = (options) => {
  const headers = buildRequestHeaders();
  return fetch(`/metrics/id_uptimes/${options.id_type}`, {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify(options),
  }).then((response) => {
    sessionStorage.setItem("url", `/id_status`);
    return handleUnauthorized(response);
  });
};

export const fetchIDValidationMetrics = (options) => {
  const headers = buildRequestHeaders();
  return fetch(`/metrics/id_validations`, {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify(options),
  }).then((response) => {
    sessionStorage.setItem("url", `/analytics`);
    return handleUnauthorized(response);
  });
};

export const fetchIDTypesByCountry = (options) => {
  const headers = buildRequestHeaders();
  return fetch(`/metrics/id_validations_by_country`, {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify(options),
  }).then((response) => {
    sessionStorage.setItem("url", `/analytics`);
    return handleUnauthorized(response);
  });
};

export const fetchPartnerAnalytics = (url, partnerId, query, controller) => {
  const headers = buildRequestHeaders();
  return fetch(`${url}?${query}&partner_id=${partnerId}`, {
    method: "GET",
    credentials: "include",
    headers,
    signal: controller?.signal,
  }).then((response) => {
    sessionStorage.setItem("url", `/analytics`);
    return handleUnauthorized(response);
  });
};

export const createSmileLink = (body, controller) => {
  const headers = buildRequestHeaders();
  headers.delete("Content-Type"); // fetch will set the multipart header
  return fetch("/web_app/smile_links", {
    method: "POST",
    credentials: "include",
    headers,
    body,
    signal: controller?.signal,
  }).then((response) => handleUnauthorized(response));
};

export const updateSmileLink = async (refId, body, controller) => {
  const headers = buildRequestHeaders();
  const response = await fetch(`/web_app/smile_links/${refId}`, {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify(body),
    signal: controller?.signal,
  });
  return handleUnauthorized(response);
};

export const fetchSmileLink = async (refId, controller) => {
  const headers = buildRequestHeaders();
  const response = await fetch(`/web_app/smile_links/${refId}`, {
    method: "GET",
    credentials: "include",
    headers,
    signal: controller?.signal,
  });
  return handleUnauthorized(response);
};

export const fetchSmileLinks = async (options, controller) => {
  const headers = buildRequestHeaders();
  const url = buildCleanUrl("web_app/smile_links", options);
  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers,
    signal: controller?.signal,
  });
  return handleUnauthorized(response);
};

export const createAccount = (accountDetails) => {
  const headers = buildRequestHeaders();
  return fetch("/account", {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify(accountDetails),
  }).then((response) => handleUnauthorized(response));
};

export const fetchUser = () => {
  const headers = buildRequestHeaders();
  return fetch("/account", {
    method: "GET",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const forgotPassword = (json) => {
  const headers = buildRequestHeaders();
  return fetch("/password/forgot", {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify(json),
  }).then((response) => handleUnauthorized(response));
};

export const resetPassword = (json) => {
  const headers = buildRequestHeaders();
  return fetch("/password/reset", {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify(json),
  }).then((response) => handleUnauthorized(response));
};

export const updateUser = (id, data) => {
  const headers = buildRequestHeaders();
  return fetch(`/account/${id}`, {
    method: "PUT",
    credentials: "include",
    headers,
    body: JSON.stringify(data),
  }).then((response) => handleUnauthorized(response));
};

export const editProducts = (json) => {
  const headers = buildRequestHeaders();
  return fetch("/account/products", {
    method: "PUT",
    credentials: "include",
    headers,
    body: JSON.stringify(json),
  }).then((response) => handleUnauthorized(response));
};

export const confirmPassword = (data) => {
  const headers = buildRequestHeaders();
  return fetch("/account/confirm_password", {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify(data),
  }).then((response) => handleUnauthorized(response));
};

export const checkEnrollmentStatus = (data) => {
  const headers = buildRequestHeaders();
  return fetch(buildUrl("account/enrolled", data), {
    method: "GET",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const fetchAPIKeys = (params, abortController) => {
  const headers = buildRequestHeaders();
  const partnerId = params.partnerId ? `&partnerId=${params.partnerId}` : "";
  return fetch(`/api_key?${partnerId}`, {
    method: "GET",
    credentials: "include",
    headers,
    signal: abortController?.signal,
  }).then((response) => handleUnauthorized(response));
};

export const generateAPIKey = (params) => {
  const headers = buildRequestHeaders();
  return fetch("/api_key", {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify(params),
  }).then((response) => handleUnauthorized(response));
};

export const updateApiKey = (params) => {
  const headers = buildRequestHeaders();
  return fetch(`/api_key/${params.id}`, {
    method: "PUT",
    credentials: "include",
    headers,
    body: JSON.stringify(params),
  }).then((response) => handleUnauthorized(response));
};

export const deleteApiKey = (params) => {
  const headers = buildRequestHeaders();
  return fetch(`/api_key/${params.id}`, {
    method: "DELETE",
    credentials: "include",
    headers,
    body: JSON.stringify(params),
  }).then((response) => handleUnauthorized(response));
};

export const fetchCallbackURL = async (params = {}) => {
  const headers = buildRequestHeaders();
  const partnerId = params.partnerId ? `partnerId=${params.partnerId}` : "";
  return fetch(`/callback?${partnerId}`, {
    method: "GET",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const setCallbackURL = (payload) => {
  const headers = buildRequestHeaders();
  return fetch("/callback", {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify(payload),
  }).then((response) => handleUnauthorized(response));
};

export const fetchPartnerInfo = (sortObject) => {
  const headers = buildRequestHeaders();
  const url = buildUrl("companies/partner_info", sortObject);
  return fetch(url, {
    method: "GET",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const asyncDownloadJobsCSV = async (sortObject) => {
  const headers = buildRequestHeaders();
  const response = await fetch("/enterprise/export", {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify(sortObject),
  });
  return handleUnauthorized(response);
};

export const asyncDownloadUsersCSV = async (sortObject) => {
  const headers = buildRequestHeaders();
  const response = await fetch("/users/export", {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify(sortObject),
  });
  return handleUnauthorized(response);
};

export const downloadJobResultSummaryPDF = async (
  job_id,
  partner_id,
  ran_as_job_type,
) => {
  const headers = buildRequestHeaders();
  let url = buildUrl(`smile_results/${partner_id}/${job_id}/generate_pdf`);
  if (ran_as_job_type === JobTypes.AML) {
    url = `/api/v3/${partner_id}/job_result_summary/${job_id}/generate_pdf?is_aml_job=true`;
  }

  return fetch(url, {
    credentials: "include",
    headers,
  }).then((resp) => resp.blob());
};

export const downloadUsageCSV = async (params) => {
  const headers = buildRequestHeaders();
  const url = buildUrl("usage/csv", params);

  return fetch(url, {
    credentials: "include",
    headers,
  }).then((resp) => resp.blob());
};

export const fetchEnrolledUser = (userID, params) => {
  const headers = buildRequestHeaders();
  const url = buildUrl(`users/${userID}`, params);
  return fetch(url, {
    method: "GET",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const fetchJobsForDashboard = (sortObject) => {
  const headers = buildRequestHeaders();
  const params = formatDateParamsToUTC(sortObject);
  const url = buildUrl(`enterprise/jobs`, params);
  return fetch(url, {
    method: "GET",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const fetchPartners = () => {
  const headers = buildRequestHeaders();
  return fetch("/companies/partner_ids", {
    method: "GET",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const allowNewEnroll = (params) => {
  const headers = buildRequestHeaders();
  return fetch("/enrollee/allow_new_enroll", {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify(params),
  }).then((response) => handleUnauthorized(response));
};

export const toggleInactive = (params) => {
  const headers = buildRequestHeaders();
  return fetch("/enrollee/toggle_inactive", {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify(params),
  }).then((response) => handleUnauthorized(response));
};

export const deleteEnrollee = (params) => {
  const headers = buildRequestHeaders();
  return fetch("/enrollee/delete", {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify(params),
  }).then((response) => handleUnauthorized(response));
};

export const fetchTeam = () => {
  const headers = buildRequestHeaders();
  return fetch("/team", {
    method: "GET",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const fetchCompany = () => {
  const headers = buildRequestHeaders();
  return fetch(`/key_contacts`, {
    method: "GET",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const fetch2FAConfiguration = () => {
  const headers = buildRequestHeaders();
  return fetch(`/team/2FA`, {
    method: "GET",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const update2FAConfiguration = () => {
  const headers = buildRequestHeaders();
  return fetch(`/team/2FA`, {
    method: "POST",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const updateContactInformation = (json) => {
  const headers = buildRequestHeaders();
  return fetch(`/key_contacts`, {
    method: "PUT",
    credentials: "include",
    headers,
    body: JSON.stringify(json),
  }).then((response) => handleUnauthorized(response));
};

export const deleteTeamMember = (id) => {
  const headers = buildRequestHeaders();
  return fetch(`/team/${id}`, {
    method: "DELETE",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const addToTeam = (json) => {
  const headers = buildRequestHeaders();
  return fetch("/team/", {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify(json),
  }).then((response) => handleUnauthorized(response));
};

export const resendInvitation = (json) => {
  const headers = buildRequestHeaders();
  return fetch("/team/resend", {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify(json),
  }).then((response) => handleUnauthorized(response));
};

export const editTeamMember = (json) => {
  const headers = buildRequestHeaders();
  return fetch(`/team/${json.id}`, {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify(json),
  }).then((response) => handleUnauthorized(response));
};

export const fetchPermissions = () => {
  const headers = buildRequestHeaders();
  return fetch("/permissions", {
    method: "GET",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const createGroup = (json) => {
  const headers = buildRequestHeaders();
  return fetch("/permissions/group", {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify(json),
  }).then((response) => handleUnauthorized(response));
};

export const fetchSDKLink = () => {
  const headers = buildRequestHeaders();
  return fetch("/companies/sdk", {
    method: "GET",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const fetchPendingReviews = (json) => {
  const headers = buildRequestHeaders();
  return fetch(buildUrl("enterprise/pending", json), {
    method: "GET",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const fetchUsage = (json) => {
  const headers = buildRequestHeaders();
  return fetch(buildUrl("usage", json), {
    method: "GET",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const fetchInvoice = (json) => {
  const headers = buildRequestHeaders();
  return fetch(`/invoices/${json.id}`, {
    method: "GET",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const fetchWallet = (params) => {
  const headers = buildRequestHeaders();
  return fetch(buildUrl(`invoices/wallet`, params), {
    method: "GET",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const fetchFundingHistory = (params) => {
  const headers = buildRequestHeaders();
  return fetch(buildUrl(`invoices/funding_history`, params), {
    method: "GET",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const fetchUsageStatementURLs = (params) => {
  const headers = buildRequestHeaders();
  return fetch(buildUrl(`usage_statements`, params), {
    method: "GET",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const createInvoice = (json) => {
  const headers = buildRequestHeaders();
  return fetch(`/invoices`, {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify(json),
  }).then((response) => handleUnauthorized(response));
};

export const fundWallet = (json) => {
  const headers = buildRequestHeaders();
  return fetch(`/dlocal/fund_wallet`, {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify(json),
  }).then((response) => handleUnauthorized(response));
};

export const fetchPaymentStatus = (json) => {
  const headers = buildRequestHeaders();
  return fetch(buildUrl(`dlocal/payment_status`, json), {
    method: "GET",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const fetchInvoices = (json) => {
  const headers = buildRequestHeaders();
  return fetch(buildUrl(`invoices`, json), {
    method: "GET",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const postPaymentMethod = (json) => {
  const headers = buildRequestHeaders();
  return fetch("/payment_methods", {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify(json),
  }).then((response) => handleUnauthorized(response));
};

export const fetchPaymentMethods = () => {
  const headers = buildRequestHeaders();
  return fetch(`/payment_methods`, {
    method: "GET",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const editPaymentMethod = (json) => {
  const headers = buildRequestHeaders();
  return fetch(`/payment_methods/${json.id}`, {
    method: "PUT",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const deletePaymentMethod = (id) => {
  const headers = buildRequestHeaders();
  return fetch(`/payment_methods/${id}`, {
    method: "DELETE",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const fetchQueueInfo = () => {
  const headers = buildRequestHeaders();
  return fetch(`/queue`, {
    method: "GET",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const retryFailedJobs = () => {
  const headers = buildRequestHeaders();
  return fetch(`/queue`, {
    method: "POST",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const pruneWorkers = () => {
  const headers = buildRequestHeaders();
  return fetch(`/queue`, {
    method: "DELETE",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const pauseWorkers = () => {
  const headers = buildRequestHeaders();
  return fetch(`/queue/pause`, {
    method: "POST",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const restartWorkers = () => {
  const headers = buildRequestHeaders();
  return fetch(`/queue/restart`, {
    method: "POST",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const fetchWebAppServices = () => {
  const headers = buildRequestHeaders();
  return fetch(`/web_app/services`, {
    method: "GET",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const fetchSmileLinksProductsConfig = (partner_id) => {
  const headers = buildRequestHeaders();
  return fetch(
    `/web_app/smile_links_products_config?partner_id=${partner_id}`,
    {
      method: "GET",
      credentials: "include",
      headers,
    },
  ).then((response) => handleUnauthorized(response));
};

export const postWebApp = (json) => {
  const headers = buildRequestHeaders();
  const url = buildWebAppUrl(json.partner_params.job_type);
  return fetch(url, {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify(json),
  }).then((response) => {
    // If we encounter a gateway timeout we don't have json in the response
    // therefore return an empty object.
    if (response.status === 504) {
      return {};
    }
    return handleUnauthorized(response);
  });
};

export const fetchUsers = (params) => {
  const headers = buildRequestHeaders();
  return fetch(buildUrl(`users`, params), {
    method: "GET",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const fetchUsersJobs = (params) => {
  const headers = buildRequestHeaders();
  return fetch(
    `/users/${params.partner_uid}/jobs${
      params.partner_id ? `?partner_id=${params.partner_id}` : ""
    }`,
    {
      method: "GET",
      credentials: "include",
      headers,
    },
  ).then((response) => handleUnauthorized(response));
};

export const fetchKYCList = (query) => {
  const headers = buildRequestHeaders();
  return fetch(buildUrl("company_kyc_details", query), {
    method: "GET",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const fetchKYCDetail = (params) => {
  const headers = buildRequestHeaders();
  return fetch(`/company_kyc_details/${params.id}`, {
    method: "GET",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const createKYCDetail = (form, partnerId) => {
  const headers = buildRequestHeaders();
  // Apparently you are not supposed to set the header using fetch with formdata
  headers.delete("Content-Type");
  return fetch(`/company_kyc_details?partner_id=${partnerId}`, {
    method: "POST",
    credentials: "include",
    headers,
    body: form,
  }).then((response) => handleUnauthorized(response));
};

export const updateKYCDetail = (form) => {
  const headers = buildRequestHeaders();
  // Apparently you are not supposed to set the header using fetch with formdata
  headers.delete("Content-Type");
  return fetch(`/company_kyc_details/${form.get("id")}`, {
    method: "PUT",
    credentials: "include",
    headers,
    body: form,
  }).then((response) => handleUnauthorized(response));
};

export const updateKYCState = (params, partnerId) => {
  const headers = buildRequestHeaders();
  return fetch(`/company_kyc_details/state?partner_id=${partnerId}`, {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify(params),
  }).then((response) => handleUnauthorized(response));
};

export const updateNINDetails = (form) => {
  const headers = buildRequestHeaders();
  // Apparently you are not supposed to set the header using fetch with formdata
  headers.delete("Content-Type");
  return fetch(`/company_nin_details`, {
    method: "PUT",
    credentials: "include",
    headers,
    body: form,
  }).then((response) => handleUnauthorized(response));
};

export const fetchNINDetails = () => {
  const headers = buildRequestHeaders();
  return fetch(`/company_nin_details`, {
    method: "GET",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const submitNINDetails = (form) => {
  const headers = buildRequestHeaders();
  headers.delete("Content-Type");
  return fetch(`/company_nin_details`, {
    method: "POST",
    credentials: "include",
    headers,
    body: form,
  }).then((response) => handleUnauthorized(response));
};

export const fetchReviewerPermissions = () => {
  const headers = buildRequestHeaders();
  return fetch("/permissions/review_permissions", {
    method: "GET",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const updateReviewerPermissions = (json) => {
  const headers = buildRequestHeaders();
  return fetch("/permissions/review_permissions", {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify(json),
  }).then((response) => handleUnauthorized(response));
};

export const addPricingPlan = ({ pricingPlan, partnerID, contractType }) => {
  const headers = buildRequestHeaders();
  const {
    contractDurationStart,
    contractDurationEnd,
    discountPercentage,
    minimumWalletBalanceUSD,
    projectedMonthlySpendUSD,
    recurringTopUpAmountUSD,
    designatedCurrency,
    enabledCurrencies,
  } = pricingPlan;

  return fetch(`/company_pricing_plans/${partnerID}`, {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify({
      contract_duration_start: contractDurationStart,
      contract_duration_end: contractDurationEnd,
      discount_percentage: discountPercentage,
      contract_type: contractType,
      minimum_wallet_balance_usd: minimumWalletBalanceUSD,
      projected_monthly_spend_usd: projectedMonthlySpendUSD,
      recurring_top_up_amount_usd: recurringTopUpAmountUSD,
      designated_currency: designatedCurrency,
      enabled_currencies: enabledCurrencies,
    }),
  });
};

export const fetchPricingPlan = (partnerID) => {
  const headers = buildRequestHeaders();
  return fetch(`/company_pricing_plans/${partnerID}`, {
    method: "GET",
    credentials: "include",
    headers,
  })
    .then((resp) => resp.json())
    .then(
      ({
        contract_duration_start,
        contract_duration_end,
        discount_percentage,
        contract_type,
        minimum_wallet_balance_usd,
        overdraft_allowance,
        projected_monthly_spend_usd,
        recurring_top_up_amount_usd,
        designated_currency,
        enabled_currencies,
        auto_pay_enabled,
        wallet_balance,
      }) => ({
        pricingPlan: {
          contractDurationStart: contract_duration_start,
          contractDurationEnd: contract_duration_end,
          discountPercentage: discount_percentage,
          contractType: contract_type,
          minimumWalletBalanceUSD: minimum_wallet_balance_usd,
          projectedMonthlySpendUSD: projected_monthly_spend_usd,
          recurringTopUpAmountUSD: recurring_top_up_amount_usd,
          designatedCurrency: designated_currency,
          enabledCurrencies: enabled_currencies,
          autoPayEnabled: auto_pay_enabled,
        },
        companyWalletDetails: {
          overdraftAllowance: overdraft_allowance,
          walletBalance: wallet_balance,
        },
      }),
    );
};

export const fetchAntiFraudMatches = (jobId) => {
  const headers = buildRequestHeaders();
  return fetch(`/users/smile_secure_matches/${jobId}`, {
    method: "GET",
    credentials: "include",
    headers,
  }).then((response) => handleUnauthorized(response));
};

export const changeAutoPaySetup = async (payload) => {
  const headers = buildRequestHeaders();
  const response = await fetch(
    buildUrl("company_pricing_plans/change_auto_pay_setup"),
    {
      method: "POST",
      credentials: "include",
      headers,
      body: typeof payload !== "string" ? JSON.stringify(payload) : payload,
    },
  );

  return handleUnauthorized(response);
};

export const deactivateAutoPay = async (payload) => {
  const headers = buildRequestHeaders();
  const response = await fetch(
    buildUrl("company_pricing_plans/deactivate_auto_pay"),
    {
      method: "POST",
      credentials: "include",
      headers,
      body: typeof payload !== "string" ? JSON.stringify(payload) : payload,
    },
  );

  return handleUnauthorized(response);
};

export const cardSetup = async (payload) => {
  const headers = buildRequestHeaders();
  const response = await fetch(buildUrl("payment_methods/card_setup"), {
    method: "POST",
    credentials: "include",
    headers,
    body: typeof payload !== "string" ? JSON.stringify(payload) : payload,
  });

  return response.json();
};

export const saveCard = async (payload) => {
  const headers = buildRequestHeaders();
  const response = await fetch(buildUrl("payment_methods/save_card"), {
    method: "POST",
    credentials: "include",
    headers,
    body: typeof payload !== "string" ? JSON.stringify(payload) : payload,
  });

  return response.json();
};

export const getCards = async (params) => {
  const headers = buildRequestHeaders();
  const response = await fetch(buildUrl("payment_methods/get_cards", params), {
    method: "GET",
    credentials: "include",
    headers,
  });

  return response.json();
};

export const initiateSSO = async (params) => {
  const headers = buildRequestHeaders();
  const response = await fetch(buildUrl("sso/auth", params), {
    method: "GET",
    credentials: "include",
    headers,
  });

  return response.json();
};

export const callbackSSO = async (params) => {
  const headers = buildRequestHeaders();
  const response = await fetch(buildUrl("sso/callback"), {
    method: "POST",
    credentials: "include",
    headers,
    body: JSON.stringify(params),
  });

  return response.json();
};

/**
 * Fetches AML news articles.
 * @param {object} options - The options for the API request.
 * @param {AbortController} controller - The AbortController used to cancel the request.
 * @returns {Promise<object>} - A promise that resolves to the AML news articles, pagination details.
 */
export const fetchNewsArticles = async (options, controller = null) => {
  const headers = buildRequestHeaders();
  const url = buildCleanUrl("jobs/aml_news", options);
  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers,
    signal: controller?.signal,
  });
  return handleUnauthorized(response);
};
