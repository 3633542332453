import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Loader } from "components/reusable/Loader";
import { useLogInWithOTP, useLogInWithRecoveryCode } from "mutations";
import SmileLock from "resources/img/brand/smile-id__symbol--32x32.svg";

function Login2FAPage() {
  const history = useHistory();
  const [isLoginWithRecoveryCode, setIsLoginWithRecoveryCode] = useState(false);
  const [OTPCode, setOTPCode] = useState("");
  const [recoveryCode, setRecoveryCode] = useState("");

  const logInWithOTP = useLogInWithOTP();
  const logInWithRecoveryCode = useLogInWithRecoveryCode();

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    if (isLoggedIn || !localStorage.preOTPUser) {
      window.location.assign("/");
    }
  }, [isLoggedIn, localStorage.preOTPUser]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const { auth_token } = isLoginWithRecoveryCode
      ? await logInWithRecoveryCode.mutateAsync({
          ...JSON.parse(localStorage.preOTPUser),
          recovery_code: recoveryCode,
        })
      : await logInWithOTP.mutateAsync({
          ...JSON.parse(localStorage.preOTPUser),
          otp_code: OTPCode,
        });
    localStorage.clear();
    localStorage.setItem("token", auth_token);
    setIsLoggedIn(true);
  };

  const switchMode = () => {
    setIsLoginWithRecoveryCode(!isLoginWithRecoveryCode);
  };

  const cancelLogIn = () => {
    localStorage.clear();
    history.push("/");
  };

  return (
    <div className="cover | bgc-dark | authentication">
      <div className="center">
        <div className="box auth-box">
          <div className="stack">
            <div className="center">
              <img
                className="smile-login__icon"
                src={SmileLock}
                alt="smile logo"
              />
            </div>
            <h1 className="text-align:center">
              Log in with {isLoginWithRecoveryCode ? "Recovery Code" : "OTP"}
            </h1>

            {isLoginWithRecoveryCode &&
              logInWithRecoveryCode.error?.response && (
                <p className="color-error">
                  {logInWithRecoveryCode.error.response.data.errors.join("\n")}
                </p>
              )}
            {!isLoginWithRecoveryCode && logInWithOTP.error?.response && (
              <p className="color-error">
                {logInWithOTP.error.response.data.errors.join("\n")}
              </p>
            )}

            <form name="enterOTPForm" className="stack" onSubmit={handleSubmit}>
              {!isLoginWithRecoveryCode && (
                <div className="stack" data-variant="tiny">
                  <div className="stack" data-variant="tiny">
                    <label htmlFor="totp-token">
                      Enter OTP generated in App
                    </label>
                    <input
                      autoFocus
                      id="totp-token"
                      className="input-text"
                      inputMode="numeric"
                      name="totp-token"
                      autoComplete="one-time-code"
                      placeholder="Enter OTP"
                      required
                      type="text"
                      pattern="([0-9]{6})|([0-9a-fA-F]{5}-?[0-9a-fA-F]{5})"
                      minLength={6}
                      maxLength={6}
                      value={OTPCode}
                      onChange={(e) => setOTPCode(e.target.value)}
                    />
                  </div>
                  <button
                    disabled={logInWithOTP.isPending}
                    className="btn-primary"
                    type="submit"
                  >
                    {logInWithOTP.isPending && (
                      <>
                        <Loader />
                        &nbsp;&nbsp;
                      </>
                    )}
                    Log in
                  </button>
                  <button
                    disabled={logInWithOTP.isPending}
                    className="btn--link"
                    type="button"
                    onClick={switchMode}
                  >
                    Log in with Recovery Code
                  </button>
                  <button
                    disabled={logInWithOTP.isPending}
                    className="btn--link"
                    type="button"
                    onClick={cancelLogIn}
                  >
                    Cancel
                  </button>
                </div>
              )}
              {isLoginWithRecoveryCode && (
                <div className="stack" data-variant="tiny">
                  <label htmlFor="recovery-code">Enter Recovery Code</label>
                  <input
                    autoFocus
                    className="input-text"
                    id="recovery-code"
                    name="recovery-code"
                    placeholder="Recovery Code"
                    required
                    type="text"
                    pattern="([a-zA-Z0-9]{10}"
                    minLength={10}
                    maxLength={10}
                    value={recoveryCode}
                    onChange={(e) => setRecoveryCode(e.target.value)}
                  />
                  <button
                    disabled={logInWithRecoveryCode.isPending}
                    className="btn-primary"
                    type="button"
                  >
                    {logInWithRecoveryCode.isPending && (
                      <>
                        <Loader />
                        &nbsp;&nbsp;
                      </>
                    )}
                    Log in
                  </button>
                  <button
                    disabled={logInWithRecoveryCode.isPending}
                    className="btn--link"
                    type="button"
                    onClick={switchMode}
                  >
                    Log in with OTP
                  </button>
                  <button
                    disabled={logInWithRecoveryCode.isPending}
                    className="btn--link"
                    type="button"
                    onClick={cancelLogIn}
                  >
                    Cancel
                  </button>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login2FAPage;
